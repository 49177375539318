import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertCircle, ArrowLeftIcon } from 'assets/icons/svg';
import { NotFoundElement } from 'components/NotFoundElement';
import style from 'assets/styles/oneElementPage.module.scss';
import { McButton, McMarkdown } from 'components/mc';
import { App, Card, Empty, Spin, Tooltip } from 'antd';
import { getPublicImageURL } from 'services/ApiService';
import { useQuery } from '@tanstack/react-query';
import applicationsService from 'services/ApplicationsService';
import appReleasesService from 'services/AppReleasesService';
import { mcErrorNotification } from 'utils/Notifications';
import defaultImage from 'assets/images/top.jpg';

export const OneApp = () => {
	let navigate = useNavigate();
	const params = useParams();
	const applicationReleaseId = !!params.appId
		? Number(params.appId)
		: undefined;
	const { notification } = App.useApp();

	const {
		data: applications,
		isLoading: isLoadingApplications,
		error: applicationsError,
	} = useQuery({
		queryKey: ['applications'],
		queryFn: () =>
			applicationsService
				.getApplicationsList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
	});

	const {
		data: applicationRelease,
		isLoading: isLoadingApplicationRelease,
		error: applicationReleaseError,
	} = useQuery({
		queryKey: ['applications', 'releases', applicationReleaseId],
		queryFn: () =>
			!!applicationReleaseId
				? appReleasesService
						.getApplicationReleaseById(applicationReleaseId)
						.then((res) => res.data)
				: undefined,
		enabled: !!applicationReleaseId,
	});

	const application = useMemo(() => {
		if (!!applicationRelease && !!applicationRelease.applicationId)
			return applications.find(
				(app) => app.id === applicationRelease?.applicationId
			);
		else return undefined;
	}, [applicationRelease, applications]);

	const {
		data: associatedEngine,
		isLoading: isLoadingAssociatedEngine,
		error: associatedEngineError,
	} = useQuery({
		queryKey: ['engines', 'releases', 'latest', applicationReleaseId],
		queryFn: () =>
			!!applicationReleaseId
				? appReleasesService
						.getLatestEngineForApplicationRelease(applicationReleaseId)
						.then((res) => res.data)
				: undefined,
		enabled: !!applicationReleaseId,
	});

	useEffect(() => {
		if (!applicationReleaseError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				applicationReleaseError,
				'fetch',
				'application release'
			)
		);
	}, [applicationReleaseError, notification]);

	useEffect(() => {
		if (!applicationsError) return;
		notification.error(
			mcErrorNotification('Error', applicationsError, 'fetch', 'applications')
		);
	}, [applicationsError, notification]);

	useEffect(() => {
		if (!associatedEngineError) return;
		notification.error(
			mcErrorNotification(
				'Error',
				associatedEngineError,
				'fetch',
				'associated engine'
			)
		);
	}, [associatedEngineError, notification]);

	const handleBackBtn = () => {
		navigate(-1);
	};

	//const noReleaseFound = !selectedApplicationRelease && !!engine;
	const appName = encodeURIComponent(application?.name ?? '');
	const hasError =
		!!applicationsError || !!associatedEngineError || !!applicationReleaseError;

	const isLoading =
		isLoadingApplicationRelease ||
		isLoadingApplications ||
		isLoadingAssociatedEngine;

	return hasError ||
		!applicationReleaseId ||
		(!applicationRelease && !application && !isLoading) ? (
		<NotFoundElement element={'application'} />
	) : isLoading ? (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				width: '100%',
				padding: '32px',
			}}
		>
			<Spin spinning={isLoading} size="large" />
		</div>
	) : (
		<div className={style.container}>
			{!!application && !!applicationRelease && !!associatedEngine ? (
				<>
					<div className={style.header}>
						{!!application.iconImagePath && (
							<img
								className={style.avatarImage}
								alt=""
								src={getPublicImageURL(application.iconImagePath)}
							/>
						)}
						<h1 className={style.title}>{`${application.name} - ${
							applicationRelease.version ?? 'Version unavailable'
						}`}</h1>
						<div className={style.btnContainer}>
							<Tooltip title={'Latest released in'}>
								<div className={style.version}>
									<AlertCircle size={20} />

									{associatedEngine.name}
								</div>
							</Tooltip>

							<McButton.Link
								href={`mailto:sales@imagesystems.se?subject=Request%20for%20more%20information%20about%20${appName}`}
								primary
							>
								Request a live demo
							</McButton.Link>
						</div>
					</div>
					<>
						<div className={style.imgContainer}>
							<img
								alt="Application thumbnail"
								src={
									applicationRelease.fullSizeImagePath
										? getPublicImageURL(applicationRelease.fullSizeImagePath)
										: defaultImage
								}
								onError={(e) => {
									e.currentTarget.src = defaultImage;
								}}
							/>
						</div>
						<div className={style.descriptionContainer}>
							<h2 className={style.descriptionTitle}>Description</h2>
							<h4 className={style.description}>
								<McMarkdown content={applicationRelease.description} />
							</h4>
						</div>
					</>
				</>
			) : (
				<Card>
					<Empty description="This application has not been released yet" />
				</Card>
			)}

			<h4 className={style.backButton} onClick={handleBackBtn}>
				<ArrowLeftIcon />
				<span>Back</span>
			</h4>
		</div>
	);
};
