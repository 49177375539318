import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import style from 'assets/styles/supportPage.module.scss';
import SupportSidebar from 'modules/Support/SupportSidebar';
import { App, Collapse, Layout, Space, Spin, Tooltip } from 'antd';
import { SearchField } from 'components/fields';
import { Link, useParams } from 'react-router-dom';
import { IconLink } from 'assets/icons/svg';
import { SupportItemTypes } from 'types/api';
import { useDebounce } from 'hooks/useDebounce';
import {
	SupportCategory,
	SupportPanelContent,
	supportCategoryByKey,
} from 'modules/Support';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import supportService from 'services/SupportService';
import { SupportItemDto } from 'api';
import tagService from 'services/TagsService';
import { mcErrorNotification } from 'utils/Notifications';
import { McButton } from 'components/mc';
import ReleaseNotes from './ReleaseNotes';

const { Content } = Layout;

const contentStyle: React.CSSProperties = {
	paddingLeft: '2rem',
};

interface CustomPlusIconProps {
	rotate: number;
}

const CustomPlusIcon: FC<CustomPlusIconProps> = ({ rotate }) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{ transform: `rotate(${rotate}deg)` }}
	>
		<path
			d="M6 9L12 15L18 9"
			stroke="#89969F"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const SupportPage: FC = () => {
	const params = useParams();
	const [activePanel, setActivePanel] = useState<string[]>(
		params.id ? [params.id] : []
	);
	const category: SupportCategory =
		(params.category as SupportCategory) ?? 'qa';

	const isReleaseNotes = category === ('release-notes' as SupportCategory);
	const categoryInfo = isReleaseNotes ? null : supportCategoryByKey[category];
	const supportItemType = categoryInfo?.itemType ?? SupportItemTypes.QA;
	const PAGE_SIZE = 10;

	const [searchedValueText, setSearchedValueText] = useState('');
	const debouncedSearchText = useDebounce(searchedValueText, 700);
	const { notification } = App.useApp();

	const [copiedId, setCopiedId] = useState<string | null>(null);

	const handleCopyLink = (itemId: number) => {
		const url = `${window.location.origin}/support/${category}/${itemId}`;
		navigator.clipboard.writeText(url);
		setCopiedId(String(itemId));
		setTimeout(() => setCopiedId(null), 2000);
	};

	const {
		data: supportItemPages,
		error: getSupportItemsError,
		hasNextPage,
		isLoading,
		isFetching,
		fetchNextPage,
	} = useInfiniteQuery({
		queryKey: [
			'supportItems',
			'infinite',
			debouncedSearchText,
			supportItemType,
		],
		queryFn: ({ pageParam: pageNr }) =>
			supportService
				.supportItemsList(
					pageNr,
					PAGE_SIZE,
					debouncedSearchText,
					supportItemType
				)
				.then((res) => res.data),
		initialPageParam: 0,
		getNextPageParam: (lastPage) => {
			const nextPageNr = lastPage.pageNumber + 1;
			return nextPageNr < lastPage.allPages ? nextPageNr : undefined;
		},
	});

	const supportItems = useMemo(
		() =>
			!!supportItemPages
				? supportItemPages.pages.reduce(
						(acc, page) => [...acc, ...page.content],
						[] as SupportItemDto[]
				  )
				: [],
		[supportItemPages]
	);

	const supportItemsCount =
		!!supportItemPages && supportItemPages.pages.length > 0
			? supportItemPages.pages[0].allElements
			: 0;

	const { data: tags, error: getTagsError } = useQuery({
		queryKey: ['tags'],
		queryFn: () => tagService.getTagsList().then((res) => res.data),
		initialData: [],
	});

	useEffect(() => {
		if (!getTagsError) return;
		notification.warning(
			mcErrorNotification('Warning', getTagsError, 'fetch', 'tags')
		);
	}, [getTagsError, notification]);

	useEffect(() => {
		if (!getSupportItemsError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getSupportItemsError,
				'fetch',
				'support items'
			)
		);
	}, [getSupportItemsError, notification]);

	const panelStyle: CSSProperties = {
		marginTop: 8,
		background: 'var(--bg)',
		border: 'none',
	};

	const firstChildPanelStyle: CSSProperties = {
		...panelStyle,
		marginTop: 0,
	};

	const handlePanelChange = (key: string | string[]) => {
		setActivePanel(Array.isArray(key) ? key : [key]);
	};

	return (
		<Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
			<Layout style={{ background: 'transparent' }}>
				<Layout hasSider style={{ background: 'transparent' }}>
					<SupportSidebar selected={category} />
					<Content style={contentStyle}>
						{isReleaseNotes ? (
							<ReleaseNotes />
						) : (
							<>
								<h3 className={style.subtitle}>
									{`Displaying ${supportItems.length} ${categoryInfo?.items} out of ${supportItemsCount}`}
								</h3>
								<div
									className="flex between center"
									style={{ gap: '1.5rem', marginBottom: '2rem' }}
								>
									<div
										style={{
											flex: 'auto',
											background: 'var(--bg-dark, #1C2225)',
											borderRadius: '1.875rem',
											padding: '0.6875rem 0',
										}}
									>
										<SearchField
											placeholder={'Search'}
											value={searchedValueText}
											onChange={(e) => setSearchedValueText(e.target.value)}
										/>
									</div>
								</div>
								<Spin spinning={isLoading}>
									<Collapse
										bordered={false}
										activeKey={activePanel}
										onChange={handlePanelChange}
										style={{
											background: 'transparent',
											overflow: 'hidden',
											borderRadius: '0.5rem',
										}}
										expandIcon={({ isActive }) => (
											<CustomPlusIcon rotate={isActive ? 180 : 0} />
										)}
										expandIconPosition="end"
										items={supportItems.map((item, index) => ({
											key: item.id,
											style: index === 0 ? firstChildPanelStyle : panelStyle,
											label: (
												<div
													className="faq-text"
													style={{
														display: 'flex',
														justifyContent: 'space-between',
													}}
												>
													<div className="flex between w-full">
														{item.question}
														<Tooltip
															title={
																copiedId === String(item.id)
																	? 'Copied to clipboard!'
																	: 'Click to copy link'
															}
														>
															<Link
																to={`/support/${category}/${item.id}`}
																onClick={(e) => {
																	e.preventDefault();
																	handleCopyLink(item.id);
																}}
																style={{
																	color: 'var(--text-normal)',
																	opacity: '0.5',
																	marginLeft: '0.5rem',
																}}
															>
																<IconLink size="1rem" />
															</Link>
														</Tooltip>
													</div>
													<div className="faq-date"></div>
												</div>
											),
											children: (
												<SupportPanelContent
													allTags={tags}
													supportItem={item}
												/>
											),
										}))}
									/>
									{hasNextPage && (
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												marginTop: '1.5rem',
											}}
											onClick={() => fetchNextPage()}
										>
											<McButton disabled={isLoading || isFetching}>
												View more
											</McButton>
										</div>
									)}
								</Spin>
							</>
						)}
					</Content>
				</Layout>
			</Layout>
		</Space>
	);
};
