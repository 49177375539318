import {
	AddLicenseActionDto,
	AdminSubscriptionDto,
	EditSubscriptionDto,
	FullDataLicenseDto,
	ModifyLicenseActionDto,
	RemoveLicenseActionDto,
	SubscriptionStatus,
	SubscriptionType,
} from 'api';
import { FormState } from 'react-hook-form';

export type LicenseAction =
	| (AddLicenseActionDto & { internalId: string })
	| ModifyLicenseActionDto
	| RemoveLicenseActionDto;

export interface LicenseInfo {
	action: LicenseAction | undefined;
	license: FullDataLicenseDto | undefined;
}

export function hasInternalId(
	action: LicenseAction
): action is AddLicenseActionDto & { internalId: string } {
	return (
		(action as AddLicenseActionDto & { internalId: string }).internalId !==
		undefined
	);
}

export function isAddAction(
	action: LicenseAction
): action is AddLicenseActionDto & { internalId: string } {
	return (
		action.type === 'ADD' &&
		'internalId' in action &&
		typeof action.internalId === 'string'
	);
}
export function isModifyAction(
	action: LicenseAction
): action is ModifyLicenseActionDto {
	return action.type === 'MODIFY';
}
export function isRemoveAction(
	action: LicenseAction
): action is RemoveLicenseActionDto {
	return action.type === 'REMOVE';
}

export interface SubscriptionFormValues {
	subscriptionId: string;
	renewalDate: string;
	adminNotes: string;
}

export const hasFormChangedHelper = (
	subscription: AdminSubscriptionDto,
	licenses: LicenseInfo[],
	formState: FormState<SubscriptionFormValues>,
	status: SubscriptionStatus,
	licenseType: SubscriptionType,
	seats: number,
	userId: number
) => {
	let addActions: AddLicenseActionDto[] = [];
	let modifyActions: ModifyLicenseActionDto[] = [];
	let removeActions: RemoveLicenseActionDto[] = [];

	if (!!licenses) {
		for (let license of licenses) {
			const action = license.action;
			if (!action) continue;

			if (isAddAction(action)) {
				addActions.push(action);
			} else if (isModifyAction(action)) {
				modifyActions.push(action);
			} else if (isRemoveAction(action)) {
				removeActions.push(action);
			}
		}
	}

	const isFormDirty = formState.isDirty;

	return {
		hasChanged:
			isFormDirty ||
			status !== subscription.status ||
			licenseType !== subscription.type ||
			seats !== subscription.seats ||
			userId !== subscription.userId ||
			addActions.length > 0 ||
			modifyActions.length > 0 ||
			removeActions.length > 0,
		addActions: addActions,
		modifyActions: modifyActions,
		removeActions: removeActions,
	};
};

export const generateSubscriptionUpdateDto = (
	subscription: AdminSubscriptionDto,
	licenses: LicenseInfo[],
	formState: FormState<SubscriptionFormValues>,
	status: SubscriptionStatus,
	licenseType: SubscriptionType,
	seats: number,
	formValues: SubscriptionFormValues,
	userId: number
) => {
	const changes = hasFormChangedHelper(
		subscription,
		licenses,
		formState,
		status,
		licenseType,
		seats,
		userId
	);

	if (!changes.hasChanged) return undefined;

	const dto: EditSubscriptionDto = {
		userId: userId !== subscription.userId ? userId : undefined,
		subscriptionID:
			formValues.subscriptionId !== subscription.subscriptionID
				? formValues.subscriptionId
				: undefined,
		renewalDate:
			formValues.renewalDate !== subscription.renewalDate
				? formValues.renewalDate
				: undefined,
		status: status !== subscription.status ? status : undefined,
		type: licenseType !== subscription.type ? licenseType : undefined,
		seats: seats !== subscription.seats ? seats : undefined,
		adminNotes:
			formValues.adminNotes !== subscription.adminNotes
				? formValues.adminNotes
				: undefined,
		addLicenseActions: changes.addActions,
		modifyLicenseActions: changes.modifyActions,
		removeLicenseActions: changes.removeActions,
	};

	return dto;
};
