/* tslint:disable */
/* eslint-disable */
/**
 * Motion Cloud
 * API for Motion Cloud web application
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface AddLicenseActionDto
 */
export interface AddLicenseActionDto {
    /**
     * 
     * @type {string}
     * @memberof AddLicenseActionDto
     */
    type: AddLicenseActionDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AddLicenseActionDto
     */
    moduleId: number;
}

export type AddLicenseActionDtoTypeEnum =
    'ADD' |
    'MODIFY' |
    'REMOVE'
    ;

export const AddLicenseActionDtoTypeEnumValues: Record<string, AddLicenseActionDtoTypeEnum> = {
        ADD: 'ADD',
        MODIFY: 'MODIFY',
        REMOVE: 'REMOVE'
}

