export type StatusSelection =
	| undefined
	| 'DRAFT'
	| 'PUBLISHED'
	| 'PUBLISHED_INTERNALLY';

export const statusSelectionValues: StatusSelection[] = [
	undefined,
	'DRAFT',
	'PUBLISHED',
	'PUBLISHED_INTERNALLY',
];
export const statusSelectionLabels = [
	'All',
	'Drafts',
	'Published',
	'Published Internally',
];

export const statusSelectionOptions: {
	label: string;
	value: Exclude<StatusSelection, undefined>;
}[] = [
	{ label: 'Published', value: 'PUBLISHED' },
	{ label: 'Draft', value: 'DRAFT' },
	{ label: 'Published Internally', value: 'PUBLISHED_INTERNALLY' },
];
