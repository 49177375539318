import McCard from 'components/cards/McCard';
import style from 'assets/styles/LicenseBlock.module.scss';
import {
	IconEdit,
	IconRemoveFromTrash,
	IconTrash,
	SearchRecentIcon,
} from 'assets/icons/svg';
import {
	AddLicenseActionDto,
	LicenseStatus,
	ModifyLicenseActionDto,
	ModuleItemDto,
} from 'api';
import {
	isAddAction,
	isModifyAction,
	isRemoveAction,
	LicenseAction,
	LicenseInfo,
} from './SubscriptionUtilityFunctions';
import { Modal, Tooltip } from 'antd';
import { CSSProperties, useState } from 'react';
import { InputDate, InputField } from 'components/fields';
import { SelectField } from 'components/selects';

export interface LicenseCardInfo {
	productCode: number;
	name: string;
	action: LicenseAction | undefined;
	licenseEntityId: number | undefined;
}

interface Props {
	modulesMap: { [key: number]: ModuleItemDto };
	licenseInfo: LicenseInfo;
	performRemoveAction: (id: string) => void;
	performExistingRemoveAction: (id: number) => void;
	performModifyAction: (
		id: number,
		modifyAction: ModifyLicenseActionDto | undefined
	) => void;
}

const LicenseCard = ({
	licenseInfo,
	modulesMap,
	performRemoveAction,
	performExistingRemoveAction,
	performModifyAction,
}: Props) => {
	const cardName = !!licenseInfo.license
		? licenseInfo.license.moduleName
		: !!licenseInfo.action && licenseInfo.action.type === 'ADD'
		? modulesMap[(licenseInfo.action as AddLicenseActionDto).moduleId].name
		: '';

	const entityId = !!licenseInfo.license ? licenseInfo.license.id : undefined;

	const [showModifyModal, setShowModifyModal] = useState<boolean>(false);
	const [modifiedDate, setModifiedDate] = useState<string | undefined>(
		undefined
	);
	const [modifiedContainerSerial, setModifiedContainerSerial] = useState<
		string | undefined
	>(undefined);
	const [modifiedStatus, setModifiedStatus] = useState<
		LicenseStatus | undefined
	>(undefined);

	const modificationDot = !!licenseInfo.action ? (
		licenseInfo.action.type === 'ADD' ? (
			<div
				className={style.statusIcon}
				style={{ backgroundColor: 'var(--add-green)' }}
			/>
		) : licenseInfo.action.type === 'REMOVE' ? (
			<div
				className={style.statusIcon}
				style={{ backgroundColor: 'var(--add-red)' }}
			/>
		) : licenseInfo.action.type === 'MODIFY' ? (
			<div
				className={style.statusIcon}
				style={{ backgroundColor: 'var(--add-orange)' }}
			/>
		) : (
			<></>
		)
	) : (
		<></>
	);

	const isCardDisabled = !!licenseInfo.license && !licenseInfo.license.enabled;

	const statusOptions: { label: string; value: LicenseStatus }[] = [
		{ label: 'Available', value: 'AVAILABLE' },
		{ label: 'Activated', value: 'ACTIVATED' },
	];

	const statusText = () => {
		if (!!licenseInfo.action && isModifyAction(licenseInfo.action)) {
			if (licenseInfo.action.newLicenseStatus === 'ACTIVATED') {
				return 'Activated';
			}
			if (licenseInfo.action.newLicenseStatus === 'AVAILABLE') {
				return <i>Available</i>;
			}
		}

		if (licenseInfo.license?.licenseStatus === 'ACTIVATED') {
			return 'Activated';
		} else {
			return <i>Available</i>;
		}
	};

	const containerText = () => {
		if (!!licenseInfo.action && isModifyAction(licenseInfo.action)) {
			if (licenseInfo.action.newLicenseStatus === 'AVAILABLE') {
				return <i>No Container</i>;
			}
			if (!!licenseInfo.action.newContainerSerial) {
				return licenseInfo.action.newContainerSerial;
			}
		}
		if (!!licenseInfo.license?.containerName) {
			return licenseInfo.license.containerName;
		}
		return <i>No Container</i>;
	};

	const expiryText = () => {
		if (!!licenseInfo.action && isModifyAction(licenseInfo.action)) {
			if (licenseInfo.action.newLicenseStatus === 'AVAILABLE') {
				return <i>No Expiry Set</i>;
			}
			if (!!licenseInfo.action.newExpiryDate) {
				return licenseInfo.action.newExpiryDate;
			}
		}
		if (!!licenseInfo.license?.expiryDate) {
			return licenseInfo.license.expiryDate;
		}
		return <i>No Expiry Set</i>;
	};

	const statusStyle: () => CSSProperties = () => {
		if (!!licenseInfo.action && isModifyAction(licenseInfo.action)) {
			if (!!licenseInfo.action.newLicenseStatus) {
				return { color: 'var(--add-orange)' };
			}
		}
		if (
			licenseInfo.license?.licenseStatus === 'AVAILABLE' ||
			!licenseInfo.license
		) {
			return { color: 'var(--text-disabled)' };
		}
		return {};
	};

	const containerSerialStyle: () => CSSProperties = () => {
		if (!!licenseInfo.action && isModifyAction(licenseInfo.action)) {
			if (licenseInfo.action.newLicenseStatus === 'AVAILABLE') {
				return { color: 'var(--add-orange)' };
			}
			if (!!licenseInfo.action.newContainerSerial) {
				return { color: 'var(--add-orange)' };
			}
		}
		if (!licenseInfo.license?.containerName) {
			return { color: 'var(--text-disabled)' };
		}
		return {};
	};

	const expiryStyle: () => CSSProperties = () => {
		if (!!licenseInfo.action && isModifyAction(licenseInfo.action)) {
			if (licenseInfo.action.newLicenseStatus === 'AVAILABLE') {
				return { color: 'var(--add-orange)' };
			}
			if (!!licenseInfo.action.newExpiryDate) {
				return { color: 'var(--add-orange)' };
			}
		}
		if (!licenseInfo.license?.expiryDate) {
			return { color: 'var(--text-disabled)' };
		}
		return {};
	};

	return (
		<McCard
			classNames={{
				body: `${style.card} ${isCardDisabled ? style.disabledCard : ''}`,
			}}
			styles={{ body: { borderRadius: 0 } }}
			style={{ borderRadius: 0 }}
			bordered={false}
		>
			<div className={style.cardInner}>
				<div className={style.columnCategory} style={{ flex: 2 }}>
					{modificationDot}
					<div>{cardName}</div>
					{!!entityId && (
						<div style={{ color: 'var(--text-disabled)' }}>
							(eid: {entityId}, ver: {licenseInfo.license?.version})
						</div>
					)}
				</div>

				<div style={statusStyle()} className={style.columnCategory}>
					<Tooltip
						title={statusText}
						overlayInnerStyle={{ textWrap: 'nowrap' }}
						overlayStyle={{ maxWidth: '500px' }}
					>
						{statusText()}
					</Tooltip>
				</div>
				<div style={containerSerialStyle()} className={style.columnCategory}>
					<Tooltip
						title={containerText}
						overlayInnerStyle={{ textWrap: 'nowrap' }}
						overlayStyle={{ maxWidth: '500px' }}
					>
						{containerText()}
					</Tooltip>
				</div>
				<div style={expiryStyle()} className={style.columnCategory}>
					<Tooltip
						title={expiryText}
						overlayInnerStyle={{ textWrap: 'nowrap' }}
						overlayStyle={{ maxWidth: '500px' }}
					>
						{expiryText()}
					</Tooltip>
				</div>
				<div
					className={style.columnCategory}
					style={{ justifyContent: 'end', flex: 1 }}
				>
					{!isCardDisabled && (
						<>
							{!!licenseInfo.action &&
								isModifyAction(licenseInfo.action) &&
								!!licenseInfo.license && (
									<div
										className={style.cardButton}
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
											if (!!licenseInfo.license) {
												performModifyAction(licenseInfo.license.id, undefined);
											}
										}}
									>
										<SearchRecentIcon />
									</div>
								)}
							{!!licenseInfo.license && (
								<div
									className={style.cardButton}
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										let status = licenseInfo.license?.licenseStatus;
										let expiry = licenseInfo.license?.expiryDate;
										let serial = licenseInfo.license?.containerName;
										if (
											!!licenseInfo.action &&
											isModifyAction(licenseInfo.action)
										) {
											if (!!licenseInfo.action.newLicenseStatus) {
												status = licenseInfo.action.newLicenseStatus;
											}
											if (!!licenseInfo.action.newExpiryDate) {
												expiry = licenseInfo.action.newExpiryDate;
											}
											if (!!licenseInfo.action.newContainerSerial) {
												serial = licenseInfo.action.newContainerSerial;
											}
										}

										setModifiedStatus(status);
										setModifiedContainerSerial(serial);
										setModifiedDate(expiry);
										setShowModifyModal(true);
									}}
								>
									<IconEdit />
								</div>
							)}
							<div
								className={style.cardButton}
								onClick={() => {
									if (!!licenseInfo.action && isAddAction(licenseInfo.action)) {
										performRemoveAction(licenseInfo.action.internalId);
									} else if (!!licenseInfo.license) {
										performExistingRemoveAction(licenseInfo.license.id);
									}
								}}
							>
								{!!licenseInfo.action && isRemoveAction(licenseInfo.action) ? (
									<IconRemoveFromTrash />
								) : (
									<IconTrash />
								)}
							</div>
						</>
					)}
				</div>
				{!!licenseInfo.license && (
					<Modal
						open={showModifyModal}
						centered
						mask
						okButtonProps={{
							disabled:
								modifiedStatus === 'ACTIVATED' &&
								(!modifiedContainerSerial ||
									modifiedContainerSerial.length === 0 ||
									!modifiedDate ||
									modifiedDate.length === 0),
						}}
						onOk={(e) => {
							e.stopPropagation();
							e.preventDefault();
							if (!!licenseInfo.license && licenseInfo.license.enabled) {
								const actionSerial =
									modifiedContainerSerial !==
										licenseInfo.license.containerName &&
									modifiedStatus !== 'AVAILABLE'
										? modifiedContainerSerial
										: undefined;

								const actionExpiry =
									modifiedDate !== licenseInfo.license.expiryDate &&
									modifiedStatus !== 'AVAILABLE'
										? modifiedDate
										: undefined;

								const actionStatus =
									modifiedStatus !== licenseInfo.license.licenseStatus
										? modifiedStatus
										: undefined;

								const hasChanged =
									!!actionStatus || !!actionExpiry || !!actionSerial;

								if (hasChanged) {
									const modifyAction: ModifyLicenseActionDto = {
										licenseId: licenseInfo.license.id,
										licenseVersion: licenseInfo.license.version,
										type: 'MODIFY',
										newLicenseStatus: actionStatus,
										newExpiryDate: actionExpiry,
										newContainerSerial: actionSerial,
									};
									performModifyAction(licenseInfo.license.id, modifyAction);
								}

								setShowModifyModal(false);
							}
						}}
						onCancel={(e) => {
							e.stopPropagation();
							e.preventDefault();
							setShowModifyModal(false);
						}}
						closable
					>
						<SelectField
							defaultValue={
								licenseInfo.license.moduleName +
								` (eid: ${licenseInfo.license.id}, ver: ${licenseInfo.license.version})`
							}
							label={'Module name'}
							disabled
						/>
						<SelectField
							value={modifiedStatus}
							defaultValue={licenseInfo.license.licenseStatus}
							options={statusOptions}
							label={'Status'}
							setSelectedField={setModifiedStatus}
						/>
						<InputField
							value={modifiedContainerSerial ?? ''}
							placeholder={'Container Serial'}
							label={`Container Serial`}
							disabled={modifiedStatus === 'AVAILABLE'}
							onChange={(e) =>
								setModifiedContainerSerial(e.target.value.trim())
							}
						/>
						<InputDate
							label={'Expiry date (UTC)'}
							error={false}
							value={modifiedDate}
							setDate={setModifiedDate}
							defaultValue={licenseInfo.license.expiryDate}
							isUtc
							timeRemainingSuffix
							timeRemainingIncludeTarget
							disabled={modifiedStatus === 'AVAILABLE'}
						/>
					</Modal>
				)}
			</div>
		</McCard>
	);
};

export default LicenseCard;
