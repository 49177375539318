import style from 'assets/styles/LicenseBlock.module.scss';
import LicenseCard from './LicenseCard';
import { App, Empty, Select, Spin, Tooltip } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import subscriptionsService from 'services/SubscriptionsService';
import {
	AddLicenseActionDto,
	AdminSubscriptionDto,
	ModifyLicenseActionDto,
	ModuleItemDto,
} from 'api';
import { infoNotification, mcErrorNotification } from 'utils/Notifications';
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import modulesService from 'services/ModulesService';
import { v4 as uuidv4 } from 'uuid';
import {
	hasInternalId,
	isRemoveAction,
	LicenseInfo,
} from './SubscriptionUtilityFunctions';
import { McButton, McDialog } from 'components/mc';
import AdminLicenseRequestTable from 'modules/AdminLicenseRequestTable';
import { HelpIcon } from 'assets/icons/svg';
import licensesService from 'services/LicenseService';
import RedDot from 'components/RedDot';

interface Props {
	subscription: AdminSubscriptionDto | 'new';
	licenses: LicenseInfo[];
	setLicenses: Dispatch<SetStateAction<LicenseInfo[]>>;
	importedExisting: boolean;
	hasOpenRequests: boolean;
	setImportedExisting: Dispatch<SetStateAction<boolean>>;
}

const LicenseBlock2 = ({
	subscription,
	licenses,
	importedExisting,
	hasOpenRequests,
	setImportedExisting,
	setLicenses,
}: Props) => {
	const isNew = subscription === 'new';
	const { notification, modal } = App.useApp();
	const [showManageRequestsWindow, setShowManageRequestsWindow] =
		useState<boolean>(false);

	const performAddAction = useCallback(
		(addAction: AddLicenseActionDto) => {
			setLicenses((prev) => [
				...prev,
				{
					action: { ...addAction, internalId: uuidv4() },
					license: undefined,
				},
			]);
		},
		[setLicenses]
	);

	const performRemoveAction = useCallback(
		(id: string) => {
			setLicenses((prev) =>
				prev.filter(
					(licenseInfo) =>
						!(
							!!licenseInfo.action &&
							hasInternalId(licenseInfo.action) &&
							licenseInfo.action.internalId === id
						)
				)
			);
		},
		[setLicenses]
	);

	const performExistingRemoveAction = useCallback(
		(id: number) => {
			setLicenses((prev) =>
				prev.map((licenseInfo) =>
					!!licenseInfo.license && licenseInfo.license.id === id
						? {
								action:
									!!licenseInfo.action && isRemoveAction(licenseInfo.action)
										? undefined
										: { type: 'REMOVE', licenseId: id },
								license: licenseInfo.license,
						  }
						: licenseInfo
				)
			);
		},
		[setLicenses]
	);

	const performModifyAction = useCallback(
		(id: number, modifyAction: ModifyLicenseActionDto | undefined) => {
			setLicenses((licenseInfo) =>
				licenseInfo.map((data) => {
					if (!data.license || data.license.id !== id) return data;

					return { action: modifyAction, license: data.license };
				})
			);
		},
		[setLicenses]
	);

	const initializeExistingLicenses = useCallback(
		(data: LicenseInfo[]) => {
			setLicenses(data);
		},
		[setLicenses]
	);

	const {
		data: modules,
		isLoading: isLoadingModules,
		error: fetchModulesError,
	} = useQuery({
		queryKey: ['persistent', 'modules'],
		staleTime: Infinity,
		gcTime: 0,
		refetchOnMount: 'always',
		queryFn: () =>
			modulesService
				.getModulesList(0, 50, undefined)
				.then((res) => res.data.content),
		initialData: [],
	});

	const modulesMap = useMemo(() => {
		return modules.reduce((acc, item) => {
			acc[item.id] = item;
			return acc;
		}, {} as { [key: number]: ModuleItemDto });
	}, [modules]);

	const selectModulesOptions = useMemo(() => {
		return modules.map((module) => ({
			value: module.id,
			label: <div className={style.optionLabel}>{module.name}</div>,
		}));
	}, [modules]);

	const {
		data: fetchedLicenses,
		isLoading: isLoadingLicenses,
		error: getLicensesError,
		failureCount: licenseFetchFailureCount,
	} = useQuery({
		queryKey: [
			'persistent',
			'subscriptions',
			isNew ? undefined : subscription.id,
			'licenses',
		],
		staleTime: Infinity,
		gcTime: 0,
		refetchOnMount: 'always',
		queryFn: () =>
			subscriptionsService
				.getAllLicenses(isNew ? -1 : subscription.id, true) // -1 should be unreachable due to the enabled condition
				.then((res) => res.data),
		enabled: !!subscription && !isNew && !importedExisting,
		initialData: undefined,
	});

	useEffect(() => {
		if (isNew && !importedExisting) {
			initializeExistingLicenses([]);
			setImportedExisting(true);
		} else if (!!fetchedLicenses && !importedExisting) {
			const existingLicenses: LicenseInfo[] = fetchedLicenses.map(
				(licenseDto) => ({
					action: undefined,
					license: licenseDto,
				})
			);
			initializeExistingLicenses(existingLicenses);
			setImportedExisting(true);
		}
	}, [
		fetchedLicenses,
		importedExisting,
		initializeExistingLicenses,
		isNew,
		setImportedExisting,
	]);

	useEffect(() => {
		if (!getLicensesError) return;
		notification.warning(
			mcErrorNotification(
				'Warning',
				getLicensesError,
				'fetch',
				'list of licenses for this subscription'
			)
		);
	}, [getLicensesError, notification]);

	useEffect(() => {
		if (!fetchModulesError) return;
		notification.error(
			mcErrorNotification('Error', fetchModulesError, 'fetch', 'module options')
		);
	});

	const { mutate: resetAllExpired, isPending: isResetingExpired } = useMutation(
		{
			mutationFn: () =>
				licensesService.resetAllExpiredLicenses().then((res) => res.data),
			onSuccess: (data) =>
				notification.success(infoNotification(data + ' rows were updated!')),
			onError: (err: unknown) => {
				notification.error(
					mcErrorNotification('Error', err, 'update', 'licenses')
				);
			},
		}
	);

	return (
		<Spin spinning={isLoadingLicenses || isLoadingModules}>
			<div className={style.blockContainer}>
				<div className={style.blockHeader}>
					<h3 className={style.blockTitle}>Licenses</h3>
					<div className={style.blockHeaderButtons}>
						<Tooltip title={'Refresh all expired licenses'}>
							<McButton
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									modal.confirm({
										title: 'Refresh expired licenses',
										content: (
											<div style={{ paddingBottom: '16px' }}>
												<p>
													This action updates all expired licenses and sets them
													to available.
												</p>
												<br />
												<b>
													<u>Note</u>
												</b>
												<p>
													This action is usually not required as similar checks
													are performed during license requests. This function
													is also performed once per day during the maintenence
													window (midnight UTC).
												</p>
											</div>
										),
										centered: true,
										maskClosable: true,
										okText: 'Refresh',
										onOk: () => {
											resetAllExpired();
										},
									});
								}}
							>
								{isResetingExpired ? (
									<div>
										<Spin spinning={isResetingExpired} />
									</div>
								) : (
									<HelpIcon />
								)}
							</McButton>
						</Tooltip>
						{!isNew && (
							<div style={{ position: 'relative' }}>
								<McButton
									onClick={(e) => {
										if (!isNew) {
											e.preventDefault();
											e.stopPropagation();
											setShowManageRequestsWindow((prev) => !prev);
										}
									}}
								>
									Manage requests
									{hasOpenRequests && (
										<RedDot
											additionalStyle={{
												position: 'absolute',
												top: '0',
												right: '0',
											}}
										/>
									)}
								</McButton>
							</div>
						)}
						<Select
							className={style.moduleSelector}
							disabled={!importedExisting}
							options={selectModulesOptions}
							style={{ minWidth: '200px' }}
							value={null}
							onChange={(val) => {
								if (!!val && importedExisting) {
									const addAction: AddLicenseActionDto = {
										type: 'ADD',
										moduleId: val,
									};
									performAddAction(addAction);
								}
							}}
							placeholder="Add license module"
						/>
					</div>
				</div>
				{!importedExisting && licenseFetchFailureCount > 0 ? (
					<div className={style.failedToLoadWarning}>
						Failed to load in potential existing licenses!
						<br />
						Please refresh the page!
					</div>
				) : !!licenses && licenses.length > 0 ? (
					<div className={style.licenseContainer}>
						{licenses.map((data) => (
							<LicenseCard
								licenseInfo={data}
								modulesMap={modulesMap}
								key={
									!!data.action && hasInternalId(data.action)
										? 'internal-' + data.action.internalId
										: data.license?.id
								}
								performExistingRemoveAction={performExistingRemoveAction}
								performModifyAction={performModifyAction}
								performRemoveAction={performRemoveAction}
							/>
						))}
					</div>
				) : (
					<div className={style.empty}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					</div>
				)}
				{showManageRequestsWindow && !isNew && (
					<McDialog
						modal
						title={'Licensing Requests'}
						footer={
							<McButton
								onClick={(e) => {
									e.stopPropagation();
									setShowManageRequestsWindow(false);
								}}
							>
								Close
							</McButton>
						}
						size={100}
					>
						<AdminLicenseRequestTable subscription={subscription} />
					</McDialog>
				)}
			</div>
		</Spin>
	);
};

export default LicenseBlock2;
